<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-crown</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold">
        Events ({{ totalEvents }})
      </v-toolbar-title>

      <!-- Toggle Button to Switch Views -->
      <v-btn-toggle mandatory v-model="currentView" class="mx-auto ml-4">
        <v-btn @click="handleChangeView(VIEW_CALENDAR_TYPE)" value="calendar">
          <v-icon
            :class="
              currentView === VIEW_CALENDAR_TYPE ? 'primary-icon' : 'grey-icon'
            "
            >mdi-calendar-month</v-icon
          >
        </v-btn>
        <v-btn @click="handleChangeView(VIEW_LIST_TYPE)" value="list">
          <v-icon
            :class="
              currentView === VIEW_LIST_TYPE ? 'primary-icon' : 'grey-icon'
            "
            >mdi-view-headline</v-icon
          >
        </v-btn>
        <v-btn @click="handleChangeView(VIEW_GROUPED_TYPE)" value="grouped">
          <v-icon
            :class="
              currentView === VIEW_GROUPED_TYPE ? 'primary-icon' : 'grey-icon'
            "
            >mdi-view-list</v-icon
          >
        </v-btn>
      </v-btn-toggle>

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'event.list.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Event
      </v-btn>

      <v-btn
        class="appbar__btn ml-5 font-weight-bold"
        height="40px"
        @click="exportEventsData"
      >
        <v-icon class="mr-2">{{ icons.export }}</v-icon>
        Export
      </v-btn>
    </v-app-bar>

    <div class="mx-4" id="event-list">
      <template v-if="currentView !== VIEW_CALENDAR_TYPE">
        <div class="appbar mt-7 mb-3">
          <v-tabs>
            <v-tab
              class="pa-3"
              v-for="item in tabs"
              :key="item.value"
              :ref="item.value"
              :disabled="defaultStatus !== item.value && loading"
              @click="handleTabClick(item.value, item.nextTime)"
            >
              {{ item.tab }}
            </v-tab>
          </v-tabs>

          <v-spacer></v-spacer>
        </div>

        <div class="appbar mt-7 mb-3">
          <v-spacer />
          <v-text-field
            v-model="filter.search"
            class="appbar__filter"
            ref="searchField"
            flat
            hide-details
            solo
            rounded
            clearable
            background-color="grey lighten-3"
            label="Search title, building, category, location and address"
            @input="search"
          ></v-text-field>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="false"
                class="ml-10"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Bulk Actions
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in actions" :key="index">
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <v-data-table
        v-if="currentView === VIEW_LIST_TYPE"
        :headers="filteredHeaders"
        :items="events"
        v-model="selected"
        @click:row="editItem"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :server-items-length="totalEvents"
        @update:options="fetch"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :items-per-page.sync="itemsPerPageTables"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.category="{ item }">
          {{ item.categoryLabel }}
        </template>
        <template v-slot:item.building="{ item }">
          {{ item.buildingName }}
        </template>
        <template v-slot:item.datetime_start="{ item }">
          {{ item.eventDate }}
        </template>
        <template v-slot:item.created_by="{ item }">
          {{ item.created_by?.full_name }}
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="
          !(events.length > 0) && !loading && currentView === VIEW_LIST_TYPE
        "
      >
        <p>No match found.</p>
      </div>
      <v-expansion-panels v-if="currentView === VIEW_GROUPED_TYPE">
        <v-expansion-panel v-for="event in eventsGrouped" :key="event.title">
          <v-expansion-panel-header>
            {{ event.title }} ({{ event.count }})
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-data-table
              :headers="filteredHeaderGroups"
              :items="groupedEvents(event.title).data"
              v-model="selectedGroup"
              show-select
              @click:row="editItem"
              :items-per-page="5"
              :loading="loading"
              :server-items-length="groupedEvents(event.title).meta.total"
              @update:options="
                (options) => {
                  fetchEventsGroupedTitle(options, event.title)
                }
              "
            >
              <template v-slot:item.category="{ item }">
                {{ item.categoryLabel }}
              </template>
              <template v-slot:item.building="{ item }">
                {{ item.buildingName }}
              </template>
              <template v-slot:item.datetime_start="{ item }">
                {{ item.eventDate }}
              </template>
              <template v-slot:item.is_featured="{ item }">
                <v-checkbox v-model="item.is_featured" readonly hide-details />
              </template>
              <template v-slot:item.hide_rsvp="{ item }">
                <v-checkbox v-model="item.hide_rsvp" readonly hide-details />
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <template v-if="currentView === VIEW_CALENDAR_TYPE">
        <FullCalendar :options="calendarOptions" :key="calendarKey" />
      </template>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Event Request list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus, mdiExportVariant } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'

import dayjs from 'dayjs'

export default {
  name: 'EventsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    FullCalendar,
  },

  data() {
    const VIEW_TYPES = {
      LIST: 'list',
      GROUPED: 'grouped',
      CALENDAR: 'calendar',
    }
    return {
      calendarKey: 0,
      calendarOptions: {
        dayMaxEvents: 3,
        headerToolbar: {
          start: 'prev',
          center: 'title',
          end: 'next',
        },
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        fixedWeekCount: false,
        showNonCurrentDates: true,
        events: async (info, successCallback, failureCallback) => {
          try {
            this.loading = true
            const params = {
              dateStart: info.start.toISOString(),
              dateEnd: info.end.toISOString(),
            }
            const timeslots = await this.getTimeSlots(params)
            const events = timeslots
              .filter((slot) => slot.event)
              .map((r) => {
                return {
                  title: r.event.title,
                  date: dayjs(r.start_time).format(),
                  textColor: 'white',
                  url: `/event/lists/${r.event_id}?tab=0`,
                  id: r.event_id,
                }
              })
            successCallback(events)
          } catch (error) {
            this.showSnackbar(this.getErrorMessage(error), false)
            failureCallback(error)
          } finally {
            this.loading = false
          }
        },
        eventClick: (info) => {
          info.jsEvent.preventDefault() // don't let the browser navigate

          if (info.event.id) {
            this.$router.push({
              name: 'event.list.details',
              params: { id: info.event.id },
            })
          }
        },
        eventOrder: 'start',
        eventDisplay: 'block',
        eventTimeFormat: {
          hour: 'numeric',
          minute: '2-digit',
          hour12: false,
        },
      },
      VIEW_LIST_TYPE: VIEW_TYPES.LIST,
      VIEW_GROUPED_TYPE: VIEW_TYPES.GROUPED,
      VIEW_CALENDAR_TYPE: VIEW_TYPES.CALENDAR,
      currentView: VIEW_TYPES.LIST,
      tabs: [
        { tab: 'Upcoming', value: 'exclude', nextTime: true },
        { tab: 'Past', value: 'only', nextTime: false },
        { tab: 'All', value: 'all', nextTime: true },
      ],
      currentPage: 1,
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
        export: mdiExportVariant,
      },
      defaultFilter: 'datetime_start',
      defaultStatus: 'exclude',
      sortOrder: false,
      selected: [],
      headers: [
        {
          text: 'Event Name',
          value: 'title',
          width: '17%',
        },
        {
          text: 'Category',
          value: 'category',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Building',
          value: 'building',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Location',
          value: 'location',
          width: '17%',
        },
        {
          text: 'Next Event Date/Time',
          value: 'datetime_start',
          width: '17%',
        },
        {
          text: 'Time Slots',
          value: 'time_slots',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Added By',
          value: 'created_by',
          align: 'center',
          width: '10%',
        },
      ],
      headerGroups: [
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'Building',
          value: 'building',
        },
        {
          text: 'Category',
          value: 'category',
        },
        {
          text: 'Event Date/Time',
          value: 'datetime_start',
        },
        {
          text: 'Location',
          value: 'location',
          align: 'center',
        },
        {
          text: 'Featured',
          value: 'is_featured',
        },
        {
          text: 'Hide RSVP',
          value: 'hide_rsvp',
        },
        {
          text: 'Added Date',
          value: 'addedDate',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
      selectedGroup: [],
      options: {},
      eventsGroupedData: {},
      showNextEventTime: true,
      paramsExport: null,
      itemsPerPageTables: 25,
    }
  },

  created() {
    this.clearEvents()
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      events: (state) => state.event.list,
      listMeta: (state) => state.event.listMeta,
      permissions: (state) => state.auth.permissions,
      totalEvents: (state) => state.event.totalEvents,
      eventsGrouped: (state) => state.event.eventsGrouped,
      paramsEvent: (state) => state.event.queryParams,
      scrollPosition: (state) => state.event.scrollPosition,
      viewType: (state) => state.event.viewType,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions([PERMISSION.EVENTS_CREATE], this.permissions)
    },

    filteredHeaders() {
      return this.showNextEventTime
        ? this.headers
        : this.headers.filter((header) => header.value !== 'datetime_start')
    },

    filteredHeaderGroups() {
      return this.showNextEventTime
        ? this.headerGroups
        : this.headerGroups.filter(
            (header) => header.value !== 'datetime_start'
          )
    },
  },

  methods: {
    ...mapActions({
      getEvents: 'event/getEvents',
      getEventsGroupedCategories: 'event/getEventsGroupedCategories',
      getEventsGroupedTitle: 'event/getEventsGroupedTitle',
      getTimeSlots: 'timeslot/getTimeSlotListByMonth',
      exportEvents: 'event/exportEvents',
    }),

    ...mapMutations({
      clearEvents: 'event/clearEventList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'event/setQueryParams',
      setScrollPosition: 'event/setScrollPosition',
      setViewType: 'event/setViewType',
    }),

    handleTabClick(status, nextTime) {
      this.defaultFilter = 'datetime_start'
      this.defaultStatus = status
      this.itemsPerPageTables = 25
      this.activeAction(status)
      this.showNextEventTime = nextTime
    },

    fetchInitParams() {
      if (this.paramsEvent?.past_events) {
        this.defaultStatus = this.paramsEvent?.past_events
      }

      if (this.paramsEvent?.itemsPerPage) {
        const { itemsPerPage } = this.paramsEvent
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsEvent?.sort) {
        if (this.paramsEvent.sort[0].includes('-')) {
          this.defaultFilter = this.paramsEvent.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsEvent?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'datetime_start'
      }

      if (this.paramsEvent?.search) {
        this.filter.search = this.paramsEvent.search
      }

      if (this.viewType) {
        this.currentView = this.viewType
      }

      this.fetch(null, 1, 'exclude', this.paramsEvent)
    },

    handleChangeView(view) {
      this.toggleView(view)
      this.setViewType(view)
      this.fetchInitParams()
    },

    filterList(filterValue) {
      this.calendarKey += 1
      this.fetch()
    },

    groupedEvents(title) {
      if (!this.eventsGroupedData[title]) {
        return {
          data: [],
          meta: { total: 0 },
        }
      }

      return this.eventsGroupedData[title]
    },

    // Method to format the date
    formatDateTime(dateTimeStr) {
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]

      // Split the input string into date and time parts
      const [datePart, timePart] = dateTimeStr.split(' ')

      // Split the date part into month, day, and year
      const [monthStr, dayStr, yearStr] = datePart.split('/')

      const month = months[parseInt(monthStr, 10) - 1]
      const day = parseInt(dayStr, 10)
      const year = parseInt(yearStr, 10)

      // Format the time part
      const formattedTime = timePart

      // Get the day of the week
      const eventDate = new Date(year, parseInt(monthStr, 10) - 1, day)
      const dayOfWeek = daysOfWeek[eventDate.getDay()]

      return `${dayOfWeek}, ${day}/${month}/${year} ${formattedTime}`
    },

    activeAction(pastTime) {
      this.fetch(null, 1, pastTime)
    },

    search: debounce(function () {
      this.fetch()
    }, 600),

    async fetch(options, page = 1, pastTime = '', eventParams = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      if (!options) {
        options = {
          sortBy: [this.defaultFilter],
          sortDesc: [this.sortOrder],
        }
      }

      let sort = options?.sortBy[0]
        ? options.sortDesc[0]
          ? '-' + options.sortBy[0]
          : options.sortBy[0]
        : ''
      if (pastTime) {
        switch (pastTime) {
          case 'exclude':
            sort = this.defaultFilter
            break
          default:
            sort = `-${this.defaultFilter}`
        }
      }

      let params = {
        page: Math.max(options?.page, page, 1),
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalEvents
            : options.itemsPerPage
          : this.itemsPerPageTables === -1
          ? this.totalEvents
          : this.itemsPerPageTables,
        past_events: this.defaultStatus,
        sort,
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearEvents()
      this.loading = true
      if (this.currentView === this.VIEW_LIST_TYPE) {
        await this.getEvents(eventParams || params)
          .catch((err) => {
            this.setLoadingBuilding(false)
            this.showSnackbar(this.getErrorMessage(err), false)
            this.setLoadingBuilding(true)
          })
          .finally(() => {
            this.loading = false
            this.setLoadingBuilding(false)
            const scrollPosition = this.scrollPosition

            this.$nextTick(() => {
              window.scrollTo({
                top: scrollPosition,
                left: 0,
                behavior: 'smooth',
              })
            })
          })
        this.setParams(params)
        this.setViewType(this.VIEW_LIST_TYPE)
      } else {
        eventParams
          ? (eventParams.grouped_by = 'title')
          : (params.grouped_by = 'title')
        await this.getEventsGroupedCategories(eventParams || params)
          .catch((err) => {
            this.setLoadingBuilding(false)
            this.showSnackbar(this.getErrorMessage(err), false)
            this.setLoadingBuilding(true)
          })
          .finally(() => {
            this.loading = false
            this.setLoadingBuilding(false)
            this.scrollToPosition()
          })
        this.setParams(params)
      }

      this.paramsExport = params
    },

    async fetchEventsGrouped(options) {
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalEvents
            : options.itemsPerPage
          : this.itemsPerPageTables === -1
          ? this.totalEvents
          : this.itemsPerPageTables,
        past_events: this.defaultStatus,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getEventsGroupedCategories(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
      this.setParams(params)
    },

    async fetchEventsGroupedTitle(options, title) {
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalEvents
            : options.itemsPerPage
          : 5,
        past_events: this.defaultStatus,
        title,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      const result = await this.getEventsGroupedTitle(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
      this.eventsGroupedData = {
        ...this.eventsGroupedData,
        ...{ [title]: result },
      }
      this.setParams(params)
      this.paramsExport = params
    },

    editItem(item) {
      this.$router.push({
        name: 'event.list.details',
        params: { id: item.id },
      })
    },

    toggleView(view) {
      if (view) {
        this.currentView = view
        this.fetch()
      }
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0 || pagination.pageStop === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },

    async getEventTimeSlots() {
      try {
        this.loading = true
        const result = await this.getTimeSlots()
        const events = result.map((r) => {
          return {
            title: r.event.title,
            date: dayjs(r.start_time).format('L'),
          }
        })
        this.calendarOptions.events = events
      } catch (error) {
        this.showSnackbar(this.getErrorMessage(error), false)
      } finally {
        this.loading = false
      }
    },

    async exportEventsData() {
      try {
        const response = await this.exportEvents(this.paramsExport)
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.ms-excel',
          })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'event_list.xlsx')
        document.body.appendChild(link)
        link.click()
        this.showSnackbar('File successfully downloaded')
      } catch (err) {
        this.showSnackbar(
          this.getErrorMessage(err, 'Oops! Something went wrong'),
          false
        )
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}

/* Custom styles for the icons */
.primary-icon {
  color: var(--v-primary-base);
  /* Change to your primary color */
}

.grey-icon {
  color: grey;
  /* Change to your desired grey color */
}

/* Remove the background and border */
.v-btn-toggle.v-btn-toggle--grouped.v-btn-toggle--active .v-btn {
  background: transparent;
  border: none;
}

/* Remove the outline */
.v-btn-toggle.v-btn-toggle--grouped .v-btn:focus {
  box-shadow: none;
}

::v-deep
  #event-list
  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard
  .fc-header-toolbar.fc-toolbar {
  justify-content: center;
  margin-bottom: 2rem;
}

::v-deep
  #event-list
  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard
  .fc-header-toolbar.fc-toolbar
  .fc-prev-button {
  background: transparent;
  border: none;
  color: $black;
  margin-right: 2rem;
  padding-top: 0;
}

::v-deep
  #event-list
  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard
  .fc-header-toolbar.fc-toolbar
  .fc-next-button {
  background: transparent;
  border: none;
  color: $black;
  margin-left: 2rem;
  padding-top: 0;
}

::v-deep #event-list .fc .fc-col-header-cell-cushion {
  text-transform: uppercase;
}

::v-deep
  #event-list
  .fc-view-harness.fc-view-harness-active
  .fc-daygrid-event-harness:nth-child(3n + 1)
  .fc-event.fc-event-start.fc-event-end {
  border-color: rgb(101, 61, 183);
  background-color: rgb(101, 61, 183);
}

::v-deep
  #event-list
  .fc-view-harness.fc-view-harness-active
  .fc-daygrid-event-harness:nth-child(3n + 2)
  .fc-event.fc-event-start.fc-event-end {
  border-color: rgba(76, 185, 211, 1);
  background-color: rgba(76, 185, 211, 1);
}

::v-deep
  #event-list
  .fc-view-harness.fc-view-harness-active
  .fc-daygrid-event-harness:nth-child(3n + 3)
  .fc-event.fc-event-start.fc-event-end {
  border-color: rgba(244, 158, 27, 1);
  background-color: rgba(244, 158, 27, 1);
}

::v-deep #event-list .fc-direction-ltr a.fc-daygrid-more-link {
  float: right !important;
}

::v-deep #event-list .fc-daygrid-day-frame .fc-daygrid-day-top {
  justify-content: center;
}

::v-deep #event-list .fc-day-other {
  background: #eeeeee;
  border-color: #e0e0e0;
}

::v-deep #event-list .fc-daygrid-day-number {
  font-weight: 600;
}

::v-deep #event-list .fc-toolbar-title {
  font-size: 1.25rem;
  text-transform: uppercase;
}

::v-deep #event-list .fc-day {
  height: 120px;
}

::v-deep #event-list .fc-day.fc-popover {
  height: fit-content;

  .fc-daygrid-event-harness a {
    padding: 10px;
    font-size: 1rem;
  }

  .fc-popover-body {
    padding: 1rem;
  }

  .fc-popover-title {
    font-size: 1rem;
  }
}

::v-deep #event-list .fc-day .fc-event-main {
  padding: 0 5px;
}
</style>
