<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-newspaper-variant</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >News Articles ({{ totalNews }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn ml-10"
        height="40px"
        :to="{ name: 'news.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create News Article
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="news-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs :value="defaultStatus">
          <v-tab
            class="pa-3"
            v-for="item in tabItems"
            :key="item.value"
            :ref="item.value"
            :disabled="defaultStatus !== item.value && loading"
            @click="handleTabClick(item.value)"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search title, building or name"
          @input="search"
        ></v-text-field>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-data-table
        :headers="headers"
        :items="newsList"
        v-model="selected"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :server-items-length="totalNews"
        @click:row="editItem"
        @update:options="fetchNews"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :items-per-page.sync="itemsPerPageTables"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.is_comment_allowed="{ item }">
          <div style="text-align: center">
            <v-checkbox v-model="item.is_comment_allowed" disabled readonly />
          </div>
        </template>
        <template v-slot:item.is_active="{ item }">
          <div style="text-align: center">
            <v-checkbox v-model="item.is_active" disabled readonly />
          </div>
        </template>
        <template v-slot:item.publish_date="{ item }">
          {{ item.publishDate }}
        </template>
        <template v-slot:item.building="{ item }">
          <div class="text-truncate" style="max-width: 200px">
            {{ item.buildings.map((building) => building.name).join(', ') }}
          </div>
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(newsList.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * News list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'NewsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      selected: [],
      active: true,
      loading: false,
      filter: {
        search: '',
      },
      tabItems: [
        { tab: 'Active ', value: 'active', index: 1 },
        { tab: 'Inactive', value: 'inactive', index: 0 },
        { tab: 'All', value: 'all', index: 2 },
      ],
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      filterBy: [
        {
          value: 'title',
          label: 'Title',
        },
        {
          value: 'publish_date',
          label: 'Publish date',
        },
      ],
      defaultStatus: 'active',
      defaultFilter: 'publish_date',
      sortOrder: false,
      headers: [
        {
          text: 'Title',
          value: 'title',
          width: '30%',
        },
        {
          text: 'Buildings',
          value: 'building',
          width: '10%',
        },
        {
          text: 'Comments',
          value: 'is_comment_allowed',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Reaction Count',
          value: 'reaction_count',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Active',
          value: 'is_active',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Created by',
          value: 'userName',
          align: 'center',
          width: '15%',
        },
        {
          text: 'Publish date',
          value: 'publish_date',
          width: '15%',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
      itemsPerPageTables: 25,
    }
  },

  created() {
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      newsList: (state) => state.news.list,
      listMeta: (state) => state.news.listMeta,
      permissions: (state) => state.auth.permissions,
      totalNews: (state) => state.news.totalNews,
      paramNews: (state) => state.news.queryParams,
      scrollPosition: (state) => state.news.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },
    statusFilter() {
      return [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Blocked',
          value: 'blocked',
        },
        {
          text: 'Deleted',
          value: 'deleted',
        },
      ]
    },

    hasCreatePermission() {
      return validatePermissions([PERMISSION.NEWS_CREATE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getNews: 'news/getNews',
    }),

    ...mapMutations({
      clearNews: 'news/clearNewsList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'news/setQueryParams',
      setScrollPosition: 'news/setScrollPosition',
    }),

    getTabNameByIndex(index) {
      switch (index) {
        case 0:
          return 'active'
        case 1:
          return 'inactive'
        default:
          return 'all'
      }
    },

    handleTabClick(status) {
      this.defaultStatus = status
      this.defaultFilter = 'publish_date'
      this.itemsPerPageTables = 25
      this.fetchNews()
    },

    fetchInitParams() {
      if (this.paramNews?.filter) {
        this.defaultStatus = this.paramNews.filter
      }

      if (this.paramNews?.itemsPerPage) {
        const { itemsPerPage } = this.paramNews
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramNews?.sort) {
        if (this.paramNews.sort[0].includes('-')) {
          this.defaultFilter = this.paramNews.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramNews?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'publish_date'
      }

      if (this.paramNews?.search) {
        this.filter.search = this.paramNews.search
      }

      this.fetchNews(null, 1, this.paramNews)
    },

    filterList(filterValue) {
      this.fetchNews(null, filterValue)
    },

    async exportList() {
      this.loading = true

      await this.exportToExcel({
        sort: this.getSortParam(),
      })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'users_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded')
        })
        .catch((err) => {
          this.showSnackbar(
            this.getErrorMessage(err, 'Oops! Something went wrong'),
            false
          )
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetchNews(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.fetchNews()
    }, 600),

    async fetchNews(options, page = 1, paramNews = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalNews
            : options.itemsPerPage
          : this.itemsPerPageTables === -1
          ? this.totalNews
          : this.itemsPerPageTables,
        filter:
          typeof this.defaultStatus === 'number'
            ? this.getTabNameByIndex(this.defaultStatus)
            : this.defaultStatus,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearNews()
      this.loading = true
      await this.getNews(paramNews || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
          this.scrollToPosition()
        })
      this.setParams(params)
    },
    calculateTotalReactions(reactionSummary) {
      const heart = reactionSummary.heart || 0
      const laugh = reactionSummary.laugh || 0
      const like = reactionSummary.like || 0
      const sad = reactionSummary.sad || 0

      return heart + laugh + like + sad
    },

    editItem(item) {
      this.$router.push({
        name: 'news.details',
        params: { id: item.id },
      })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.v-list-item__title {
  cursor: pointer;
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
</style>
